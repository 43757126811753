import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import person from "./../assets/yaser-montazeri-developer.jpg";
import petjoint from "./../assets/petjoint.jpg";
import petjointLogo from "./../assets/petjoint-logo.png";
import dmk from "./../assets/dmk.jpg";
import dmkLogo from "./../assets/dmk-logo.png";
import universityAzad from "./../assets/university-azad.png";
import universityTehran from "./../assets/university-tehran.png";
import overc from "./../assets/overC.jpg";
import rouhani from "./../assets/rouhani.jpg";
import rouhaniLogo from "./../assets/rouhani-logo.png";
import Footer from "./footer/Footer";
import { useEffect, useState } from "react";

const name: string = "Yaser Montazeri";

const counterList = [
  { number: 24, title: "Websites" },
  { number: 9, title: "Companies" },
  { number: 6, title: "Frameworks" },
  { number: 20, title: "Years of experiences" },
];

const skillList = [
  {
    title: "Html",
    value: 95,
  },
  {
    title: "Angular",
    value: 95,
  },
  {
    title: "Css",
    value: 95,
  },
  {
    title: "React",
    value: 75,
  },
  {
    title: "Bootstrap",
    value: 80,
  },
  {
    title: "Javascript",
    value: 85,
  },
  {
    title: "Tailwind",
    value: 95,
  },
  {
    title: "Typescript",
    value: 95,
  },
  {
    title: "SEO",
    value: 95,
  },
  {
    title: "Content Management",
    value: 95,
  },
];

const experienceList = [
  {
    year: 2023,
    title: "Front-End",
    company: "Nashravaran",
    desc: "Implemented cutting-edge front-end technologies to develop | Maintained site revision and optimization according to site maintenance needs and SEO strategies",
    currentJob: true,
  },
  {
    year: "2008 - 2023",
    title: "Web Product Manager",
    company: "Nashravaran",
    desc: "Monitoring project progress, evaluating feedback, documenting processes, and managing diverse teams",
    currentJob: false,
  },
  {
    year: "2010 - 2016",
    title: "Web Product Manager",
    company: "Sls",
    desc: "Managed a cross-functional team consisting of three members (product, engineering, and support) and 25 skilled experts to ensure the successful launch of an important governmental website for journalists and reporters.",
    currentJob: false,
  },
  {
    year: "2016 - 2017",
    title: "Web Product Manager",
    company: "Ministry of Culture",
    desc: "Web Product Manager & Database administration at the 22nd & 23rd Press Exhibition in Tehran, Iran.",
    currentJob: false,
  },
];

function App() {
  useEffect(() => {
    AOS.init();
    sideMenuScroll();
    document.title = `${name} - Web Developer`;
    setTimeout(() => {
      AOS.refresh();
    }, 10);
    if (window.innerWidth < 1024) {
      const sideMenu = document.getElementById("sideMenu");
      sideMenu?.classList.replace("translate-x-32", "translate-x-0");
    }
  }, []);

  const [isSkillScroll, setSkillScroll] = useState(false);

  function goDown() {
    document.getElementById("counter")?.scrollIntoView({ behavior: "smooth" });
  }

  function scrollMenu(height: number) {
    window.scrollTo({ top: height, behavior: "smooth" });
  }

  function sideMenuScroll() {
    const sideMenu = document.getElementById("sideMenu");

    if (window.innerWidth > 1024) {
      if (window.scrollY >= window.innerHeight) {
        sideMenu?.classList.replace("translate-x-32", "translate-x-0");
        sideMenu?.classList.replace("translate-y-28", "translate-y-0");
      } else {
        sideMenu?.classList.replace("translate-x-0", "translate-x-32");
        sideMenu?.classList.replace("translate-y-28", "translate-y-0");
      }
    } else {
      if (window.scrollY < window.innerHeight) {
        sideMenu?.classList.add("translate-y-28");
        sideMenu?.classList.remove("translate-y-0");
        sideMenu?.classList.replace("translate-x-32", "translate-x-0");
      } else {
        sideMenu?.classList.add("translate-y-0");
        sideMenu?.classList.remove("translate-y-28");
        sideMenu?.classList.replace("translate-x-32", "translate-x-0");
      }
    }
  }

  window.addEventListener("resize", (event) => {
    sideMenuScroll();
  });

  window.addEventListener("scroll", (event) => {
    const topBar = document.getElementById("topBar");
    const experience = document.getElementById("Experience");
    const petJoint = document.getElementById("PetJoint");
    const divanMohasebat = document.getElementById("Dmk");
    const overC = document.getElementById("Overc");
    const rouhani = document.getElementById("Rouhani");
    const sideMenu = document.getElementById("sideMenu");
    const skills = document.getElementById("skills");

    if (
      experience &&
      window.scrollY > experience?.offsetTop + experience.offsetHeight
    ) {
      topBar?.classList.replace("h-4", "h-0");
    } else {
      topBar?.classList.replace("h-0", "h-4");
    }

    if (petJoint && divanMohasebat && overC && rouhani) {
      if (window.scrollY < petJoint.offsetTop && window.scrollY >= 0) {
        sideMenu?.classList.add("personal");
        sideMenu?.classList.remove("petjoint");
        sideMenu?.classList.remove("personall");
      }
      if (
        petJoint.offsetTop <= window.scrollY &&
        window.scrollY < divanMohasebat.offsetTop
      ) {
        sideMenu?.classList.add("petjoint");
        sideMenu?.classList.remove("personal");
        sideMenu?.classList.remove("dmk");
      }
      if (
        divanMohasebat.offsetTop <= window.scrollY &&
        window.scrollY < overC.offsetTop
      ) {
        sideMenu?.classList.add("dmk");
        sideMenu?.classList.remove("petjoint");
        sideMenu?.classList.remove("overc");
      }
      if (
        overC.offsetTop <= window.scrollY &&
        window.scrollY < rouhani.offsetTop
      ) {
        sideMenu?.classList.add("overc");
        sideMenu?.classList.remove("dmk");
        sideMenu?.classList.remove("rouhani");
      }
      if (rouhani.offsetTop <= window.scrollY) {
        sideMenu?.classList.add("rouhani");
        sideMenu?.classList.remove("overc");
      }
    }

    sideMenuScroll();

    if (skills) {
      if (window.scrollY >= skills.offsetTop - window.innerHeight + 400) {
        setSkillScroll(true);
      } else {
        setSkillScroll(false);
      }
    }
  });

  return (
    <>
      <aside
        id="sideMenu"
        className="w-full lg:w-16 h-auto lg:h-screen fixed bottom-4 lg:top-0 right-0 lg:right-6 grid content-end justify-center lg:justify-start lg:content-center z-[200] translate-y-28 translate-x-32 duration-700 personall"
      >
        <div
          className="w-[90vw] lg:w-full h-20 lg:h-[420px] bg-neutral-900 rounded-lg grid grid-cols-5 lg:grid-cols-1 grid-rows-1 lg:grid-rows-5 mx-auto lg:mx-0"
          style={{ boxShadow: "0 0.4375rem 1.25rem rgba(0,0,0,0.15)" }}
        >
          <div
            className="w-auto lg:w-16 h-full col-span-1 row-span-1 rounded-l-lg lg:rounded-bl-none lg:rounded-t-lg flex justify-center items-center cursor-pointer active-personal"
            onClick={() => scrollMenu(window.innerHeight)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.8}
              className="w-6 h-6 stroke-stone-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
          </div>
          <div
            className="w-auto lg:w-16 h-full col-span-1 row-span-1 flex justify-center items-center cursor-pointer active-petjoint"
            onClick={() => {
              let item = document.getElementById("PetJoint");
              scrollMenu(item ? item?.offsetTop : 0);
            }}
          >
            <img
              className="w-8 h-8 brightness-[30%]"
              src={petjointLogo}
              alt=""
            />
          </div>
          <div
            className="w-auto lg:w-16 h-full col-span-1 row-span-1 flex justify-center items-center cursor-pointer active-dmk"
            onClick={() => {
              let item = document.getElementById("Dmk");
              scrollMenu(item ? item?.offsetTop : 0);
            }}
          >
            <img className="w-8 h-8 brightness-[30%]" src={dmkLogo} alt="" />
          </div>
          <div
            className="w-auto lg:w-16 h-full col-span-1 row-span-1 flex justify-center items-center cursor-pointer active-overc"
            onClick={() => {
              let item = document.getElementById("Overc");
              scrollMenu(item ? item?.offsetTop : 0);
            }}
          >
            <img
              className="w-8 h-8 brightness-[30%]"
              src={petjointLogo}
              alt=""
            />
          </div>
          <div
            className="w-auto lg:w-16 h-full col-span-1 row-span-1 rounded-r-lg lg:rounded-tr-none lg:rounded-b-lg flex justify-center items-center cursor-pointer active-rouhani"
            onClick={() => {
              let item = document.getElementById("Rouhani");
              scrollMenu(item ? item?.offsetTop : 0);
            }}
          >
            <img
              className="w-8 h-8 brightness-[30%]"
              src={rouhaniLogo}
              alt=""
            />
          </div>
        </div>
      </aside>

      <div
        onClick={goDown}
        className="w-full h-auto hidden lg:block absolute bottom-4 left-0 animate-pulse"
      >
        <span className="text-[14px] text-white text-center uppercase block cursor-pointer select-none opacity-30">
          Scroll Down
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mx-auto text-white cursor-pointer opacity-30"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>
      <main className="overflow-x-hidden">
        <div className="w-full h-screen hidden lg:block absolute top-0 overflow-hidden -z-20">
          <div className="w-full h-[136%] bg-app-200 -rotate-[15deg] absolute -top-[50] -left-[83%] -z-20"></div>
        </div>
        <section className="w-full h-screen px-14 py-14 grid grid-cols-12 content-center lg:content-normal gap-y-12 lg:gap-y-0">
          <div
            className="w-[70%] lg:w-full h-auto lg:h-full max-w-xs max-h-[320px] lg:max-h-full lg:max-w-full aspect-square lg:aspect-auto rounded-full lg:rounded-3xl col-span-12 lg:col-span-4 mx-auto lg:mx-0 border-8 lg:border-0 border-neutral-800 overflow-hidden"
            style={{
              boxShadow: "0 0 7px rgba(0,0,0,.9)",
            }}
          >
            <img
              className="w-full h-full object-cover object-top hover:scale-110 duration-700 "
              src={person}
              alt="yaser montazeri"
            />
          </div>

          <article className="w-full h-auto col-span-12 lg:col-span-8 grid content-center justify-center gap-y-4">
            <h1
              className=" text-app-200 text-[32px] lg:text-[52px] font-extrabold uppercase relative before:absolute before:-left-16 before:top-9 lg:before:h-1 lg:before:w-10 before:rounded-xl before:content-[''] before:bg-app-200 lg:leading-[62px] text-center lg:text-start"
              data-aos="fade-left"
              data-aos-duration="2400"
            >
              I'm {name}
              <span className="animate-pulse">.</span>
              <br />
              <span className="text-white inline-block">Web Developer</span>
            </h1>
            <h2
              className="w-auto lg:w-[492px] text-white text-center lg:text-start text-[14px] lg:text-[16px] lg:leading-8"
              data-aos="fade-right"
              data-aos-duration="2400"
            >
              With a career spanning 10+ years, I've navigated the realms of
              product management, system data analysis, and designed intricate
              data workflows in some companies. My expertise also extends to 6
              years in search engine optimization (SEO), software designing and
              development, crafting innovative solutions that meet diverse
              business needs.
            </h2>
            <button
              className="w-32 lg:w-36 h-12 lg:h-14 rounded-full bg-transparent border-2 border-app-200 text-app-200 text-[20px] font-medium duration-500 relative overflow-hidden mx-auto lg:mx-0 mt-2 lg:mt-0 group"
              data-aos="fade-up"
              data-aos-duration="2400"
            >
              <span
                className="w-0 h-12 lg:h-14 group-hover:w-full bg-app-200 absolute top-0
             left-0 flex justify-center items-center delay-100 duration-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={4}
                  className="w-6 h-6 stroke-white translate-x-5 lg:translate-x-6 -translate-y-[2px] animate-pulse"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
                <h2 className="translate-x-16 lg:translate-x-[70px] -translate-y-[2px] text-[14px] lg:text-[16px]">
                  MORE
                </h2>
              </span>
            </button>
          </article>
        </section>

        <section
          id="counter"
          className="w-full h-auto lg:h-52 px-12 lg:px-10 py-10 lg:py-0 bg-app-200 grid grid-cols-2 lg:grid-cols-4 grid-rows-2 lg:grid-rows-1 gap-y-32 content-center "
        >
          {counterList.map((items, index) => {
            return (
              <div
                className="w-full h-auto lg:h-52 col-span-1 row-span-1 grid justify-center content-center text-white lg:counter-after"
                key={index}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={index * 300}
              >
                <label className="text-[24px] lg:text-[30px] text-center font-bold before:content-['+_'] before:opacity-40 leading-[60px] -translate-x-2">
                  {items.number}
                </label>
                <label className="text-[16px] lg:text-[18px] text-center font-semibold uppercase opacity-60">
                  {items.title}
                </label>
              </div>
            );
          })}
        </section>
        <section
          id="topBar"
          className="sticky top-0 w-full h-4 duration-700 z-50 bg-app-200"
        ></section>

        <section id="skills" className="w-full h-auto mt-28">
          <h4
            className="text-center text-white text-[36px] font-black uppercase mb-24"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <span className="opacity-20 mr-2 select-none">---</span> Skills
            <span className="opacity-20 ml-2 select-none">---</span>
          </h4>
          <div className="w-full h-auto grid grid-cols-1 lg:grid-cols-2 gap-x-14 gap-y-12 px-12 lg:px-32">
            {skillList.map((items, index) => {
              return (
                <div className="w-full h-auto col-span-1 grid " key={index}>
                  <article
                    className="w-full h-auto group"
                    onMouseOver={(e) => {
                      const skillTitle = e.currentTarget.querySelector("h2");
                      if (skillTitle) {
                        skillTitle.innerText = `${items.value}%`;
                      }
                    }}
                    onMouseOut={(e) => {
                      const skillTitle = e.currentTarget.querySelector("h2");
                      if (skillTitle) {
                        skillTitle.innerText = items.title;
                      }
                    }}
                  >
                    <h2 className="text-app-200 text-[14px] uppercase mb-1">
                      {items.title}
                    </h2>
                    <div className="w-full h-[6px] bg-neutral-800 rounded-xl translate-y-[6px]"></div>
                    <div className="relative z-10 flex justify-start items-start">
                      <span
                        className="h-[6px] bg-app-200 rounded-xl duration-1000"
                        style={{
                          width: `${isSkillScroll ? items.value : 0}%`,
                          transitionDelay: `${index * 200}ms`,
                        }}
                      ></span>
                      <span className="w-4 h-4 rounded-full bg-app-200 -translate-x-2 -translate-y-[5px]"></span>
                    </div>
                  </article>
                </div>
              );
            })}
          </div>
        </section>

        <span className="w-1/2 h-[1px] bg-neutral-900 my-24 mx-auto block"></span>

        <section id="Education" className="w-full h-auto mb-36 ">
          <h4
            className="text-center text-white text-[36px] font-black uppercase mb-24"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <span className="opacity-20 mr-2 select-none">---</span>Education
            <span className="opacity-20 ml-2 select-none">---</span>
          </h4>
          <div className="w-full h-auto grid grid-cols-1 lg:grid-cols-2 gap-x-24 gap-y-20 px-12 lg:px-32">
            <div
              className="w-auto h-auto lg:h-52 flex justify-start items-center col-span-1 py-2 mx-auto lg:mx-0"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <div className="w-[30%] lg:w-[40%] max-w-[100px] lg:max-w-[80px] h-auto py-8">
                <img
                  className="w-auto h-auto hue-rotate-180"
                  src={universityAzad}
                  alt=""
                />
              </div>
              <div className="w-auto h-full py-12 mx-8">
                <span className="w-[1px] h-full bg-neutral-800 block"></span>
              </div>
              <article className="w-auto h-full py-6 grid content-center gap-y-2">
                <h2 className="text-app-200 text-[22px] lg:text-[28px] font-black uppercase">
                  Computer Engineering
                </h2>
                <h2 className="text-white text-[18px] lg:text-[24px] font-semibold uppercase opacity-70">
                  Bachelor's degree
                </h2>
                <h2 className="text-white text-[16px] lg:text-[18px] opacity-40 ">
                  Islamic Azad University
                </h2>
              </article>
            </div>
            <div
              className="w-auto h-auto lg:h-52 flex justify-start items-center col-span-1 py-2 mx-auto lg:mx-0"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="1000"
            >
              <div className="w-[30%] lg:w-[40%] max-w-[100px] lg:max-w-[110px] h-auto py-8">
                <img
                  className="w-auto h-auto hue-rotate-180"
                  src={universityTehran}
                  alt=""
                />
              </div>
              <div className="w-auto h-full py-12 mx-8">
                <span className="w-[1px] h-full bg-neutral-800 block"></span>
              </div>
              <article className="w-auto h-full py-6 grid content-center gap-y-2">
                <h2 className="text-app-200 text-[22px] lg:text-[28px] font-black uppercase">
                  MBA
                </h2>
                <h2 className="text-white text-[18px] lg:text-[24px] font-semibold uppercase opacity-70">
                  Master's degree
                </h2>
                <h2 className="text-white text-[16px] lg:text-[18px] opacity-40 ">
                  University Of Tehran
                </h2>
              </article>
            </div>
          </div>
        </section>

        <span className="w-1/2 h-[1px] bg-neutral-900 my-24 mx-auto block"></span>

        <section id="Experience" className="w-full h-auto mb-36 ">
          <h4
            className="text-center text-white text-[36px] font-black uppercase mb-24"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <span className="opacity-20 mr-2 select-none">---</span>Experience
            <span className="opacity-20 ml-2 select-none">---</span>
          </h4>
          <div className="w-full h-auto py-6 grid grid-cols-1 lg:grid-cols-2 gap-x-24 gap-y-24 px-12 lg:px-32 uppercase">
            {experienceList.map((items, index) => {
              return (
                <div
                  id="job-card"
                  className="w-auto h-auto col-span-1 relative pl-[60px] z-20 after:absolute after:top-0 after:left-[20px] after:bottom-0 after:border-l after:border-neutral-800 after:-z-50"
                  key={index}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay={window.innerWidth > 1024 ? index * 500 : 200}
                >
                  <div
                    id="job-icon"
                    className="w-10 h-10 bg-app-200 rounded-full absolute top-0 left-0 flex justify-center items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-5 h-5 fill-white"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clipRule="evenodd"
                      />
                      <path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
                    </svg>
                  </div>
                  <span
                    id="job-time"
                    className="text-[12px] text-white bg-neutral-800 opacity-80 inline-block py-[2px] px-2 mb-3 rounded-full"
                  >
                    {items.year} {items.currentJob ? "- present" : ""}
                  </span>
                  <h2
                    id="job-title"
                    className="text-white text-[18px] font-medium mt-[7px] mb-[10px]"
                  >
                    {items.title}
                    <span className="text-[15px] font-light pl-[26px] relative opacity-60 before:absolute before:content-[''] before:w-[10px] before:h-[2px] before:bg-white before:opacity-60 before:top-[9px] before:left-[7px] ">
                      {items.company}
                    </span>
                  </h2>
                  <h2
                    id="job-desc"
                    className="text-[14px] text-white font-light text-justify lg:text-start"
                  >
                    {items.desc}
                  </h2>
                </div>
              );
            })}
          </div>
        </section>

        <span className="w-1/2 h-[1px] bg-neutral-900 my-24 mx-auto block"></span>

        <h4
          className="text-center text-white text-[36px] font-black uppercase mb-24"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <span className="opacity-20 mr-2 select-none">---</span> Portfolio
          <span className="opacity-20 ml-2 select-none">---</span>
        </h4>

        <section
          id="PetJoint"
          className="w-full h-auto relative bg-cover bg-center bg-no-repeat md:bg-fixed"
          style={{
            backgroundImage: `url(${petjoint})`,
          }}
        >
          <section className="w-full h-full bg-black bg-opacity-90 absolute"></section>
          <section
            className="w-full h-[87vh] lg:h-[90vh] grid content-center px-12 lg:px-14 py-14"
            data-aos="fade-up"
            data-aos-duration="1600"
            data-aos-delay="400"
          >
            <div>
              <div
                className="w-[54%] lg:w-[22%] h-auto max-w-[280px] aspect-square rounded-[50%] bg-cover bg-top hover:bg-bottom bg-no-repeat duration-[2000ms] relative z-30 mx-auto"
                style={{
                  backgroundImage: `url(${petjoint})`,
                  boxShadow: "0 0 7px rgba(0,0,0,.9)",
                }}
              ></div>

              <article className="w-full h-auto col-span-8 grid content-center justify-center gap-y-4 relative z-30 mt-8">
                <h1 className=" text-petjoint text-[32px] lg:text-[52px] font-extrabold uppercase text-center before:bg-petjoint lg:leading-[62px]">
                  PetJoint.
                  <br />
                  <span className="text-white inline-block">
                    Online Pet Clinic
                  </span>
                </h1>

                <a
                  className="w-32 lg:w-36 h-12 lg:h-14 rounded-full bg-transparent border-2 border-petjoint text-petjoint text-[20px] font-medium duration-500 relative overflow-hidden mx-auto mt-2 lg:mt-0 group"
                  href="https://petjoint.ir"
                  target="_blank"
                >
                  <span
                    className="w-0 h-12 lg:h-14 group-hover:w-full bg-petjoint absolute top-0
             left-0 flex justify-center items-center delay-100 duration-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4}
                      className="w-6 h-6 stroke-white translate-x-5 lg:translate-x-9 -translate-y-[2px] animate-pulse"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                    <label className="translate-x-16 lg:translate-x-[70px] -translate-y-[2px] text-[14px] lg:text-[16px]">
                      PETJOINT
                    </label>
                  </span>
                </a>
              </article>
            </div>
          </section>
          <section
            id="counter"
            className="w-full h-auto lg:h-52 px-12 lg:px-10 py-10 lg:py-0 bg-petjoint grid grid-cols-2 lg:grid-cols-4 grid-rows-2 lg:grid-rows-1 gap-x-2 gap-y-24 content-center relative z-30 "
          >
            {counterList.map((items, index) => {
              return (
                <div
                  className="w-full h-auto lg:h-52 col-span-1 row-span-1 grid justify-center content-center text-white lg:counter-after"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay={index * 300}
                >
                  <label className="text-[24px] lg:text-[30px] text-center font-bold before:content-['+_'] before:opacity-40 leading-[60px] -translate-x-2">
                    {items.number}
                  </label>
                  <label className="text-[14px] lg:text-[18px] text-center font-semibold uppercase opacity-60">
                    {items.title}
                  </label>
                </div>
              );
            })}
          </section>
          <h2
            className="text-justify text-white px-12 lg:px-32 pt-12 pb-32 text-opacity-60 leading-9 font-light relative z-30 bg-petjoint"
            style={{ textAlignLast: "center" }}
          >
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam
            incidunt omnis porro aliquam quidem nisi quas unde molestias. Ipsam
            aut eius ipsa sapiente sint reiciendis soluta magnam, voluptatum
            laboriosam laudantium consequuntur quaerat quod voluptatem
            recusandae eveniet alias officia mollitia deleniti harum provident
            hic. Soluta, quo cumque aut odio voluptates quibusdam incidunt
            deleniti necessitatibus nulla rerum possimus distinctio accusantium
            reiciendis amet magni ipsa tempora ab, iusto deserunt natus qui illo
            perferendis hic officia. Tenetur possimus ipsam quod voluptatibus
            tempora exercitationem, ut ad vitae dolores. Corporis debitis
            consequatur, dolores quaerat quisquam nesciunt. Rerum earum sed
            officia tempora quaerat fugiat asperiores reprehenderit, ipsum
            obcaecati similique illo ullam, repellendus, aspernatur deserunt
            ipsam. Veritatis quo ad sunt necessitatibus amet exercitationem
            itaque omnis fugit nisi mollitia.
          </h2>
        </section>

        <section
          id="Dmk"
          className="w-full h-auto relative bg-cover bg-center bg-no-repeat md:bg-fixed"
          style={{
            backgroundImage: `url(${dmk})`,
          }}
        >
          <section className="w-full h-full bg-black bg-opacity-90 absolute"></section>
          <section
            className="w-full h-[87vh] lg:h-[90vh] grid content-center px-12 lg:px-14 py-14"
            data-aos="fade-up"
            data-aos-duration="1600"
            data-aos-delay="400"
          >
            <div>
              <div
                className="w-[54%] lg:w-[22%] h-auto max-w-[280px] aspect-square rounded-[50%] bg-cover bg-top hover:bg-bottom bg-no-repeat duration-[2000ms] relative z-30 mx-auto"
                style={{
                  backgroundImage: `url(${dmk})`,
                  boxShadow: "0 0 7px rgba(0,0,0,.9)",
                }}
              ></div>

              <article className="w-full h-auto col-span-8 grid content-center justify-center gap-y-4 relative z-30 mt-8">
                <h1 className=" text-dmk text-[32px] lg:text-[52px] font-extrabold uppercase text-center before:bg-dmk lg:leading-[62px]">
                  Dmk.
                  <br />
                  <span className="text-white inline-block">
                    Online Pet Clinic
                  </span>
                </h1>

                <a
                  className="w-32 lg:w-36 h-12 lg:h-14 rounded-full bg-transparent border-2 border-dmk text-dmk text-[20px] font-medium duration-500 relative overflow-hidden mx-auto mt-2 lg:mt-0 group"
                  href="http://dmk2.netiran.ir"
                  target="_blank"
                >
                  <span
                    className="w-0 h-12 lg:h-14 group-hover:w-full bg-dmk absolute top-0
             left-0 flex justify-center items-center delay-100 duration-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4}
                      className="w-6 h-6 stroke-white translate-x-5 lg:translate-x-5 -translate-y-[2px] animate-pulse"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                    <label className="translate-x-16 lg:translate-x-[70px] -translate-y-[2px] text-[14px] lg:text-[16px]">
                      DMK
                    </label>
                  </span>
                </a>
              </article>
            </div>
          </section>
          <section
            id="counter"
            className="w-full h-auto lg:h-52 px-12 lg:px-10 py-10 lg:py-0 bg-dmk grid grid-cols-2 lg:grid-cols-4 grid-rows-2 lg:grid-rows-1 gap-x-2 gap-y-24 content-center relative z-30 "
          >
            {counterList.map((items, index) => {
              return (
                <div
                  className="w-full h-auto lg:h-52 col-span-1 row-span-1 grid justify-center content-center text-white lg:counter-after"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay={index * 300}
                >
                  <label className="text-[24px] lg:text-[30px] text-center font-bold before:content-['+_'] before:opacity-40 leading-[60px] -translate-x-2">
                    {items.number}
                  </label>
                  <label className="text-[14px] lg:text-[18px] text-center font-semibold uppercase opacity-60">
                    {items.title}
                  </label>
                </div>
              );
            })}
          </section>
          <h2
            className="text-justify text-white px-12 lg:px-32 pt-12 pb-32 text-opacity-60 leading-9 font-light relative z-30 bg-dmk"
            style={{ textAlignLast: "center" }}
          >
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam
            incidunt omnis porro aliquam quidem nisi quas unde molestias. Ipsam
            aut eius ipsa sapiente sint reiciendis soluta magnam, voluptatum
            laboriosam laudantium consequuntur quaerat quod voluptatem
            recusandae eveniet alias officia mollitia deleniti harum provident
            hic. Soluta, quo cumque aut odio voluptates quibusdam incidunt
            deleniti necessitatibus nulla rerum possimus distinctio accusantium
            reiciendis amet magni ipsa tempora ab, iusto deserunt natus qui illo
            perferendis hic officia. Tenetur possimus ipsam quod voluptatibus
            tempora exercitationem, ut ad vitae dolores. Corporis debitis
            consequatur, dolores quaerat quisquam nesciunt. Rerum earum sed
            officia tempora quaerat fugiat asperiores reprehenderit, ipsum
            obcaecati similique illo ullam, repellendus, aspernatur deserunt
            ipsam. Veritatis quo ad sunt necessitatibus amet exercitationem
            itaque omnis fugit nisi mollitia.
          </h2>
        </section>

        <section
          id="Overc"
          className="w-full h-auto relative bg-cover bg-center bg-no-repeat md:bg-fixed"
          style={{
            backgroundImage: `url(${overc})`,
          }}
        >
          <section className="w-full h-full bg-black bg-opacity-90 absolute"></section>
          <section
            className="w-full h-[87vh] lg:h-[90vh] grid content-center px-12 lg:px-14 py-14"
            data-aos="fade-up"
            data-aos-duration="1600"
            data-aos-delay="400"
          >
            <div>
              <div
                className="w-[54%] lg:w-[22%] h-auto max-w-[280px] aspect-square rounded-[50%] bg-cover bg-top hover:bg-bottom bg-no-repeat duration-[2000ms] relative z-30 mx-auto"
                style={{
                  backgroundImage: `url(${overc})`,
                  boxShadow: "0 0 7px rgba(0,0,0,.9)",
                }}
              ></div>

              <article className="w-full h-auto col-span-8 grid content-center justify-center gap-y-4 relative z-30 mt-8">
                <h1 className=" text-overc text-[32px] lg:text-[52px] font-extrabold uppercase text-center before:bg-overc lg:leading-[62px]">
                  overc.
                  <br />
                  <span className="text-white">Online Pet Clinic</span>
                </h1>

                <a
                  className="w-32 lg:w-36 h-12 lg:h-14 rounded-full bg-transparent border-2 border-overc text-overc text-[20px] font-medium duration-500 relative overflow-hidden mx-auto mt-2 lg:mt-0 group"
                  href="https://overc.io"
                  target="_blank"
                >
                  <span
                    className="w-0 h-12 lg:h-14 group-hover:w-full bg-overc absolute top-0
             left-0 flex justify-center items-center delay-100 duration-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4}
                      className="w-6 h-6 stroke-white translate-x-5 lg:translate-x-6 -translate-y-[2px] animate-pulse"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                    <label className="translate-x-16 lg:translate-x-[70px] -translate-y-[2px] text-[14px] lg:text-[16px]">
                      OVERC
                    </label>
                  </span>
                </a>
              </article>
            </div>
          </section>
          <section
            id="counter"
            className="w-full h-auto lg:h-52 px-12 lg:px-10 py-10 lg:py-0 bg-overc grid grid-cols-2 lg:grid-cols-4 grid-rows-2 lg:grid-rows-1 gap-x-2 gap-y-24 content-center relative z-30 "
          >
            {counterList.map((items, index) => {
              return (
                <div
                  className="w-full h-auto lg:h-52 col-span-1 row-span-1 grid justify-center content-center text-white lg:counter-after"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay={index * 300}
                >
                  <label className="text-[24px] lg:text-[30px] text-center font-bold before:content-['+_'] before:opacity-40 leading-[60px] -translate-x-2">
                    {items.number}
                  </label>
                  <label className="text-[14px] lg:text-[18px] text-center font-semibold uppercase opacity-60">
                    {items.title}
                  </label>
                </div>
              );
            })}
          </section>
          <h2
            className="text-justify text-white px-12 lg:px-32 pt-12 pb-32 text-opacity-60 leading-9 font-light relative z-30 bg-overc"
            style={{ textAlignLast: "center" }}
          >
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam
            incidunt omnis porro aliquam quidem nisi quas unde molestias. Ipsam
            aut eius ipsa sapiente sint reiciendis soluta magnam, voluptatum
            laboriosam laudantium consequuntur quaerat quod voluptatem
            recusandae eveniet alias officia mollitia deleniti harum provident
            hic. Soluta, quo cumque aut odio voluptates quibusdam incidunt
            deleniti necessitatibus nulla rerum possimus distinctio accusantium
            reiciendis amet magni ipsa tempora ab, iusto deserunt natus qui illo
            perferendis hic officia. Tenetur possimus ipsam quod voluptatibus
            tempora exercitationem, ut ad vitae dolores. Corporis debitis
            consequatur, dolores quaerat quisquam nesciunt. Rerum earum sed
            officia tempora quaerat fugiat asperiores reprehenderit, ipsum
            obcaecati similique illo ullam, repellendus, aspernatur deserunt
            ipsam. Veritatis quo ad sunt necessitatibus amet exercitationem
            itaque omnis fugit nisi mollitia.
          </h2>
        </section>

        <section
          id="Rouhani"
          className="w-full h-auto relative bg-cover bg-center bg-no-repeat md:bg-fixed"
          style={{
            backgroundImage: `url(${rouhani})`,
          }}
        >
          <section className="w-full h-full bg-black bg-opacity-90 absolute"></section>
          <section
            className="w-full h-[87vh] lg:h-[90vh] grid content-center px-12 lg:px-14 py-14"
            data-aos="fade-up"
            data-aos-duration="1600"
            data-aos-delay="400"
          >
            <div>
              <div
                className="w-[54%] lg:w-[22%] h-auto max-w-[280px] aspect-square rounded-[50%] bg-cover bg-top hover:bg-bottom bg-no-repeat duration-[2000ms] relative z-30 mx-auto"
                style={{
                  backgroundImage: `url(${rouhani})`,
                  boxShadow: "0 0 7px rgba(0,0,0,.9)",
                }}
              ></div>

              <article className="w-full h-auto col-span-8 grid content-center justify-center gap-y-4 relative z-30 mt-8">
                <h1 className=" text-rouhani text-[32px] lg:text-[52px] font-extrabold uppercase text-center before:bg-rouhani lg:leading-[62px]">
                  rouhani.
                  <br />
                  <span className="text-white">Online Pet Clinic</span>
                </h1>

                <a
                  className="w-32 lg:w-36 h-12 lg:h-14 rounded-full bg-transparent border-2 border-rouhani text-rouhani text-[20px] font-medium duration-500 relative overflow-hidden mx-auto mt-2 lg:mt-0 group"
                  href="https://rouhanihassan.com"
                  target="_blank"
                >
                  <span
                    className="w-0 h-12 lg:h-14 group-hover:w-full bg-rouhani absolute top-0
             left-0 flex justify-center items-center delay-100 duration-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={4}
                      className="w-6 h-6 stroke-white translate-x-5 lg:translate-x-9 -translate-y-[2px] animate-pulse"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                    <label className="translate-x-16 lg:translate-x-[70px] -translate-y-[2px] text-[14px] lg:text-[16px]">
                      ROUHANI
                    </label>
                  </span>
                </a>
              </article>
            </div>
          </section>
          <section
            id="counter"
            className="w-full h-auto lg:h-52 px-12 lg:px-10 py-10 lg:py-0 bg-rouhani grid grid-cols-2 lg:grid-cols-4 grid-rows-2 lg:grid-rows-1 gap-x-2 gap-y-24 content-center relative z-30 "
          >
            {counterList.map((items, index) => {
              return (
                <div
                  className="w-full h-auto lg:h-52 col-span-1 row-span-1 grid justify-center content-center text-white lg:counter-after"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay={index * 300}
                >
                  <label className="text-[24px] lg:text-[30px] text-center font-bold before:content-['+_'] before:opacity-40 leading-[60px] -translate-x-2">
                    {items.number}
                  </label>
                  <label className="text-[14px] lg:text-[18px] text-center font-semibold uppercase opacity-60">
                    {items.title}
                  </label>
                </div>
              );
            })}
          </section>
          <h2
            className="text-justify text-white px-12 lg:px-32 pt-12 pb-32 text-opacity-60 leading-9 font-light relative z-30 bg-rouhani"
            style={{ textAlignLast: "center" }}
          >
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam
            incidunt omnis porro aliquam quidem nisi quas unde molestias. Ipsam
            aut eius ipsa sapiente sint reiciendis soluta magnam, voluptatum
            laboriosam laudantium consequuntur quaerat quod voluptatem
            recusandae eveniet alias officia mollitia deleniti harum provident
            hic. Soluta, quo cumque aut odio voluptates quibusdam incidunt
            deleniti necessitatibus nulla rerum possimus distinctio accusantium
            reiciendis amet magni ipsa tempora ab, iusto deserunt natus qui illo
            perferendis hic officia. Tenetur possimus ipsam quod voluptatibus
            tempora exercitationem, ut ad vitae dolores. Corporis debitis
            consequatur, dolores quaerat quisquam nesciunt. Rerum earum sed
            officia tempora quaerat fugiat asperiores reprehenderit, ipsum
            obcaecati similique illo ullam, repellendus, aspernatur deserunt
            ipsam. Veritatis quo ad sunt necessitatibus amet exercitationem
            itaque omnis fugit nisi mollitia.
          </h2>
        </section>

        <span className="w-1/2 h-1 my-36 mx-auto block "></span>

        <Footer />

        {/* <section className="w-full h-screen"></section> */}
      </main>
    </>
  );
}

export default App;
