import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="relative translate-y-6">
        <div className="waves z-10">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          <div className="wave" id="wave4"></div>
        </div>
      </div>
      <footer className="w-full h-auto grid px-12 pb-40 lg:pb-6 relative bg-[#1a1a1a]">
        <section className="w-full h-auto grid grid-cols-2 gap-y-8 lg:flex justify-between items-center relative z-20">
          <div
            id="social-media"
            className="w-full lg:w-auto h-auto col-span-2 grid grid-cols-2 gap-y-8 lg:flex justify-start items-center gap-x-6 opacity-80"
          >
            <div className="w-full lg:w-auto h-auto col-span-2 justify-center flex lg:justify-start items-center gap-x-10 lg:gap-x-6 opacity-80">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 md:w-[30px] md:h-[30px] cursor-pointer"
              >
                <g fill="none" fillRule="evenodd">
                  <g
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.4"
                    className="stroke-gray-50"
                  >
                    <path d="M15.7 22.5L13 25.2a.9.9 0 01-1.5-.4l-1.8-6"></path>
                    <path d="M18.8 14.6l-5.2 4.7c-.5.5-.5 1.3 0 1.7l7.2 5.3c.7.5 1.8.2 2-.7L26.2 9a1 1 0 00-1.4-1.2l-20.7 8a.7.7 0 000 1.3l5.5 1.7"></path>
                  </g>
                  <path d="M0 32V0h32v32z"></path>
                </g>
              </svg>
              <a
                href="https://www.linkedin.com/in/yasermontazeri/"
                target="_blank"
              >
                <svg
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 md:w-[30px] md:h-[30px] cursor-pointer"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.2"
                      d="M10 4h12a6 6 0 016 6v12a6 6 0 01-6 6H10a6 6 0 01-6-6V10a6 6 0 016-6h0zM10.8 14.8V22"
                      className="stroke-gray-50"
                    ></path>
                    <g
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.2"
                      className="stroke-gray-50"
                    >
                      <path d="M15.6 22v-4.2a3 3 0 013-3h0a3 3 0 013 3V22M10.8 10.5c-.1 0-.3 0-.3.3a.3.3 0 10.3-.3"></path>
                    </g>
                    <path d="M0 32V0h32v32z"></path>
                  </g>
                </svg>
              </a>
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 md:w-[30px] md:h-[30px] cursor-pointer"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    d="M10 4h12a6 6 0 016 6v12a6 6 0 01-6 6H10a6 6 0 01-6-6V10a6 6 0 016-6h0z"
                    className="stroke-gray-50"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    d="M22.6 9c-.2 0-.5.2-.5.4a.5.5 0 10.5-.4M19.4 12.6a4.8 4.8 0 11-6.8 6.8 4.8 4.8 0 016.8-6.8"
                    className="stroke-gray-50"
                  ></path>
                  <path d="M0 32V0h32v32z"></path>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="22px"
                height="22px"
                viewBox="0 0 50 50"
                className="fill-gray-50 mt-[3px] cursor-pointer"
              >
                <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
              </svg>
            </div>
            <div className="w-[1.6px] h-5 mt-1 bg-white opacity-10 hidden lg:block"></div>

            <div className="w-auto h-auto hidden lg:flex justify-center lg:justify-start items-center gap-x-2 mt-[2px] opacity-80">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.8}
                className="w-7 h-7 stroke-gray-50 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>

              <h2 className="text-[14px] text-gray-50 font-extralight ">
                Ontario, Ca
              </h2>
            </div>
          </div>
          <div className="col-span-2 flex justify-center items-center gap-x-12 lg:gap-x-0 lg:block lg:justify-start lg:items-start ">
            <div className="w-auto h-auto flex lg:hidden justify-center items-center gap-x-2 mt-[2px] opacity-80">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.8}
                className="w-7 h-7 stroke-gray-50 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>

              <h2 className="text-[14px] text-gray-50 font-extralight ">
                Ontario, Ca
              </h2>
            </div>
            <button className="w-auto h-auto px-6 py-2 border-[0.6px] border-gray-50 rounded-md text-gray-50 text-[13.2px] font-extralight uppercase lg:mb-3 opacity-80">
              Get In Touch
            </button>
          </div>
        </section>
        <div className="w-full h-[1px] bg-white opacity-[0] my-2"></div>
        <section className="w-full h-auto grid lg:flex justify-center lg:justify-between items-center relative z-20">
          <h4 className="text-gray-50 text-[12px] font-extralight opacity-30 mt-4 order-2 lg:order-1">
            Copyright © {new Date().getFullYear()} Yaser Montazeri. All rights
            reserved.
          </h4>
          <div className="w-auto h-auto flex justify-between items-center gap-x-4 text-gray-50 text-[13.4px] font-extralight opacity-30 mt-4 order-1 lg:order-2">
            <p className="cursor-pointer">Skills</p>
            <p className="cursor-pointer">Education</p>
            <p className="cursor-pointer">Experience</p>
            <p className="cursor-pointer">Portfolio</p>
          </div>
        </section>
      </footer>
    </>
  );
}

export default Footer;
